import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
      <div className="bg-gray-800 rounded-lg p-8 max-w-3xl w-full h-5/6 mx-4 overflow-y-auto">
        <button
          onClick={onClose}
          className="text-white float-right mb-4 text-2xl"
        >
          ✖
        </button>
        <div className="text-center h-full">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;