import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase'; // Ensure this is correctly set up
import { FaThumbsUp, FaTrophy } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Modal from './RateMyIdeasModal';

function RateMyIdeas() {
  const [user, loading] = useAuthState(auth);
  const [responses, setResponses] = useState([]);
  const [newResponse, setNewResponse] = useState('');
  const [viewingAnswers, setViewingAnswers] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentQuestion = 'What is the best way to validate a startup idea?';
  const [winner, setWinner] = useState(null);

  useEffect(() => {
    // Fetch responses from the server
    setResponses([
      { id: 1, text: 'Conduct market research', votes: 5 },
      { id: 2, text: 'Build a minimum viable product', votes: 3 },
    ]);

    // Determine the winner (for demonstration purposes)
    const maxVotes = Math.max(...responses.map(r => r.votes), 0);
    const winningResponse = responses.find(r => r.votes === maxVotes);
    setWinner(winningResponse);
  }, [responses]);

  const handleResponseSubmit = () => {
    if (newResponse.trim() && user) {
      setResponses([...responses, { id: responses.length + 1, text: newResponse, votes: 0 }]);
      setNewResponse('');
      setIsModalOpen(false); // Close the modal after submission
    }
  };

  const handleVote = (id) => {
    if (user) {
      setResponses(responses.map(response =>
        response.id === id ? { ...response, votes: response.votes + 1 } : response
      ));
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen w-full bg-gradient-to-r from-gray-900 via-gray-800 to-black flex flex-col justify-between">
      <div className="flex-grow flex items-center">
        <div className="max-w-3xl mx-auto px-4 py-16 text-center mt-16">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-10 font-sans tracking-tight leading-tight">
            🎉 Rate My Ideas Game 🎉
          </h1>
          <h2 className="text-xl md:text-3xl font-bold text-white mb-20">
            Share your thoughts, vote on others, and win the trophy every 48 hours!
          </h2>
          <div className="flex flex-col md:flex-row justify-center gap-8 mb-32">
            <ContentBubble title="Submit Your Ideas" className="md:w-1/3">
              Share your innovative business ideas and get feedback from peers.
            </ContentBubble>
            <ContentBubble title="Vote on Ideas" className="md:w-1/3">
              Participate in the community by voting on others' ideas.
            </ContentBubble>
            <ContentBubble title="Win the Trophy" className="md:w-1/3">
              The most voted idea wins the trophy every 48 hours!
            </ContentBubble>
          </div>
          <div className="bg-gray-800 rounded-lg p-6 mb-6 transition-colors border-4 border-gradient-to-r from-cyan-400 via-blue-500 to-pink-500 max-w-md mx-auto">
            <h2 className="text-xl font-bold text-white mb-4">{currentQuestion}</h2>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => setIsModalOpen(true)}
                className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Answer
              </button>
              <button
                onClick={() => setViewingAnswers(true)}
                className="p-2 bg-green-500 text-white rounded hover:bg-green-600"
              >
                View Answers
              </button>
            </div>
          </div>
          {user ? (
            viewingAnswers ? (
              <div className="bg-gray-800 rounded-lg p-6 mb-6 transition-colors border-4 border-gradient-to-r from-cyan-400 via-blue-500 to-pink-500 max-w-md mx-auto">
                <ul className="list-disc pl-5">
                  {responses.map((response) => (
                    <li key={response.id} className="flex justify-between items-center mb-2">
                      <span className="flex-grow text-white">{response.text}</span>
                      <button
                        onClick={() => handleVote(response.id)}
                        className="ml-2 p-1 bg-green-500 text-white rounded hover:bg-green-600 flex items-center"
                      >
                        <FaThumbsUp className="mr-1" /> Vote ({response.votes})
                      </button>
                    </li>
                  ))}
                </ul>
                {winner && (
                  <div className="mt-6 p-4 bg-yellow-500 text-black rounded-lg">
                    <FaTrophy className="inline-block mr-2" /> Current Winner: {winner.text}
                  </div>
                )}
              </div>
            ) : null
          ) : (
            <p className="text-white">Please <Link to="/login" className="text-blue-300 hover:underline">sign in</Link> to participate.</p>
          )}
        </div>
      </div>
      <Footer />
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-xl font-bold text-white mb-4">Submit Your Idea</h2>
        <input
          type="text"
          value={newResponse}
          onChange={(e) => setNewResponse(e.target.value)}
          className="w-full p-2 border rounded bg-gray-700 text-white mb-4"
          placeholder="Your thoughts..."
        />
        <button
          onClick={handleResponseSubmit}
          className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </Modal>
    </div>
  );
}

const ContentBubble = ({ title, children, className = "" }) => {
  return (
    <div className={`bg-gray-800 rounded-xl p-6 text-white ${className}`}>
      <h2 className="text-xl font-semibold mb-2">{title}</h2>
      <p className="text-sm text-gray-300">{children}</p>
    </div>
  );
};

function Footer() {
  return (
    <footer className="bg-gray-800 py-4 sm:py-6">
      <div className="max-w-4xl mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
        <p className="text-gray-400 text-xs sm:text-sm mb-2 sm:mb-0">© 2024 - Rate My Ideas by <span className="bg-gradient-to-r from-cyan-400 to-blue-500 bg-clip-text text-transparent">Home</span>of<span className="bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">Founders</span></p>
        <div className="flex space-x-4">
          <Link to="/blogs" className="text-gray-400 hover:text-white text-xs sm:text-sm">
            Blog
          </Link>
          <Link to="/contact" className="text-gray-400 hover:text-white text-xs sm:text-sm">
            Contact Us
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default RateMyIdeas;